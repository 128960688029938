import React from 'react';
import propTypes from 'prop-types';

import Layout from '../components/layout';
import SEO from '../components/seo';

import videoStyles from './videos.module.scss';

const ContactPage = ({ location }) => (
  <Layout>
    <SEO
      title="Termite Videos"
      pathname={location.pathname}
      keywords={[
        `termite videos`,
        `customer review`,
        `drywood`,
        `wood damage`,
        `subterranean termite`,
      ]}
    />
    <div className={videoStyles.wrapper}>
      <div className={videoStyles.container}>
        <div className="columns">
          <div className="column">
            <div className={videoStyles.videoContainer}>
              <h3>Customer Reviews</h3>
              <div className={videoStyles.video}>
                <iframe
                  title="Customer Reviews"
                  width="560"
                  height="560"
                  src="https://www.youtube.com/embed/-MzFJNzPWtA"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className={videoStyles.videoContainer}>
              <h3>Drywood Termite Activity</h3>
              <div className={videoStyles.video}>
                <iframe
                  title="Drywood Termite Activity"
                  width="560"
                  height="560"
                  src="https://www.youtube.com/embed/tMOL-BpR1NE"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={videoStyles.container}>
        <div className="columns">
          <div className="column">
            <div className={videoStyles.videoContainer}>
              <h3>Subterranean Termite Activity in Garage</h3>
              <div className={videoStyles.video}>
                <iframe
                  title="Subterranean Termite Activity in Garage"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/R9SU8_7cPmU"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>

          <div className="column">
            <div className={videoStyles.videoContainer}>
              <h3>Subterranean Termite Activity III</h3>
              <div className={videoStyles.video}>
                <iframe
                  title="Subterranean Termite Activity III"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/7WBKnceos50"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={videoStyles.container}>
        <div className="columns">
          <div className="column">
            <div className={videoStyles.videoContainer}>
              <h3>Subterranean Termite Activity II</h3>
              <div className={videoStyles.video}>
                <iframe
                  title="Subterranean Termite Activity II"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/C31o6W1z2cU"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>

          <div className="column">
            <div className={videoStyles.videoContainer}>
              <h3>Subterranean Termite Activity I</h3>
              <div className={videoStyles.video}>
                <iframe
                  title="Subterranean Termite Activity I"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/AUMv9lcgcco"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default ContactPage;

ContactPage.propTypes = {
  location: propTypes.shape({
    pathname: propTypes.string,
  }),
};

ContactPage.defaultProps = {
  location: ``,
};
